export const msalConfig = {
    auth: {
        clientId: "b11d6826-3dc6-443e-b9c4-338300524888",
        //authority: "https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        authority: "https://login.microsoftonline.com/organizations/",
        redirectUri: "https://bsmmaster.scgp.co.th/",
        //redirectUri: "http://localhost:3000/",
        navigateToLoginRequestUrl: false,
        validateAuthority: false,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};
