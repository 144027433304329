import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilPlus,
  cilPen,
  cilCog,
  cilUser,
  cilNotes,
  cilTrash,
  cilFile,
  cilCheck,
  cilSearch,
  cilLockLocked,
  cilMedicalCross,
  cilScrubber,
  cilXCircle,
  cilMagnifyingGlass,
  cilArrowThickTop,
  cilBuilding,
  cilHistory,
  cilCopy,
  cilExternalLink
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilPlus,
    cilPen,
    cilCog,
    cilUser,
    cilNotes,
    cilTrash,
    cilFile,
    cilCheck,
    cilSearch,
    cilLockLocked,
    cilMedicalCross,
    cilScrubber,
    cilXCircle,
    cilMagnifyingGlass,
    cilArrowThickTop,
    cilBuilding,
    cilHistory,
    cilCopy,
    cilExternalLink
  }
);
