import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  userinfo: {},
  userrole:{},
  role:"",
  token:"",
  matData: {
    request: {},
    material: {},
    createByUser: "",
    SpecificationData: {},
    Basicdata: {
      Basicdata1: {},
      Basicdata2: {},
      AdditionalData: {
        Description: [],
        UnitOfMeasure: {
          list: [],
          grp: {},
        },
        BasicDataText: {
          list: [],
          lang: {},
        },
        InspectionText: {
          list: [],
          lang: {},
        },
        InternalComment: {
          list: [],
          lang: {},
        },
        ProportionUnit: {
          list: [],
          head: {},
        },
      }
    },
    Orglevel: {
      OrglevelPlant: [],
      OrglevelSale: [],
      OrglevelStorage: [],
      OrglevelAccount: [],
      OrglevelWh: [],
    },
    SaleData: {
      SaleOrg1Data: {},
      SaleOrg2Data: {},
      SaleTextData: {
        text: {},
        list: []
      },
      SaleGenPlantData: {},
      ForeTradeExp: {},
    },
    Purchasing: {
      Purchasing: {},
      ForeignTradeImport: {},
      OrderText: {
        text: {},
        list: []
      },
    },
    ClasSifiData: {},
    ClasSifiDetData: [],
    CommentData: [],
    WorkScheduleData: {},
    Plant: {},
    MRPData: {
      Mrp1: {},
      Mrp2: {},
      Mrp3: {},
      Mrp4: {},
    },
    AccountingData: {},
    CostingData: {},
  }
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case "set_user":
      return { ...state, ...rest };
    case 'clear_all':
      return initialState;
    // case 'set_mat':
    //   return { ...state.matData, ...rest };
    default:
      return state
  }
}

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  changeState,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    persistedReducer,
    //composeWithDevTools(applyMiddleware(logger, thunk)),
    composeWithDevTools(applyMiddleware(thunk))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
