export const VERSION_WEB = "1.0.11";

// export const WEB_FILE_URL = "https://localhost:44347";
// export const WEB_API = "https://localhost:44347/api/";
// export const WEB_FILE_URL = "http://122.155.0.74:5301";
// export const WEB_API = "http://122.155.0.74:5301/api/";
export const WEB_API = "https://scgp-matmaster-mgmt-api-prd.azurewebsites.net/api/";
export const WEB_FILE_URL = "https://scgp-matmaster-mgmt-api-prd.azurewebsites.net";
//export const WEB_API = "https://scgp-matmaster-mgmt-api-dev.azurewebsites.net/api/";
//export const WEB_FILE_URL = "https://scgp-matmaster-mgmt-api-dev.azurewebsites.net";
// export const adfs_redirect_url = 'http://localhost:3990/';
// export const adfs_client_id = '7197d1ad-7dc9-4163-9179-f3ad748817f4';
